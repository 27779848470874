export const footballData = [
  {
    name: 'Tom Brady',
    firstName: "Tom",
    lastName: "Brady",
    year: 2007,
    position: 'QB',
    team: 'NWE',
    fullTeamName: 'New England Patriots',
    aproximateValue: 199,
    aproximateValuePeak: 23, 
    overallRating: 99,
    teamPlayStyle: 'Field General',
    plyrHeadshotSrc: 'https://www.pro-football-reference.com/req/20230307/images/headshots/BradTo00_2022.jpg',
    plyrPictureSrc: 'https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEg4CC05HdNY2Z9_TmM5WP-BreYKSehsr0pMQw38TxEArkixVqS70KTFnaVnzjJG9CdpaLFOedezF0k_K_-f0_PnLvic3KBXY6oVD9moDPpjVw_fj4sbNR31V0q_Y3fbcA-7o19yNUeD9ig/s1600/Brady_Tom6_Patriots.jpg',
    teamPrimary: '',
    teamSecondary: '',
    teamAlternate: '',
    teamLogo: '',
    teamLogoStyling: '',
    cardColor: 'sapphire',
  },
  {
    name: 'Jim Brown',
    firstName: "Jim",
    lastName: "Brown",
    year: 1965,
    position: 'FB',
    team: 'CLE',
    fullTeamName: 'Cleveland Browns',
    aproximateValue: 98,
    aproximateValuePeak: 22, 
    overallRating: 99,
    teamPlayStyle: 'Power Back',
    plyrHeadshotSrc: '',
    plyrPictureSrc: '',
    teamPrimary: '',
    teamSecondary: '',
    teamAlternate: '',
    teamLogo: '',
    teamLogoStyling: '',
    cardColor: '',
  },
  {
    name: 'Lawrence Taylor',
    firstName: "Lawrence",
    lastName: "Taylor",
    year: 1986,
    position: 'OLB',
    team: 'NYG',
    fullTeamName: 'New York Giants',
    aproximateValue: 156,
    aproximateValuePeak: 21, 
    overallRating: 99,
    teamPlayStyle: 'Power Rusher',
    plyrHeadshotSrc: '',
    plyrPictureSrc: '',
    teamPrimary: '',
    teamSecondary: '',
    teamAlternate: '',
    teamLogo: '',
    teamLogoStyling: '',
    cardColor: '',
  },
  {
    name: 'Peyton Manning',
    firstName: "Peyton",
    lastName: "Manning",
    year: 2004,
    position: 'QB',
    team: 'IND',
    fullTeamName: 'Indiannapolis Colts',
    aproximateValue: 256,
    aproximateValuePeak: 23, 
    overallRating: 99,
    teamPlayStyle: 'Field General',
    plyrHeadshotSrc: '',
    plyrPictureSrc: '',
    teamPrimary: '',
    teamSecondary: '',
    teamAlternate: '',
    teamLogo: '',
    teamLogoStyling: '',
    cardColor: '',
  },
  {
    name: 'Jerry Rice',
    firstName: "Jerry",
    lastName: "Rice",
    year: 1994,
    position: 'WR',
    team: 'SF',
    fullTeamName: 'San Fransisco 49ers',
    aproximateValue: 98,
    aproximateValuePeak: 21, 
    overallRating: 99,
    teamPlayStyle: 'Playmaker',
    plyrHeadshotSrc: 'https://m.media-amazon.com/images/M/MV5BMTM2Njg0MzgwOF5BMl5BanBnXkFtZTYwOTc3Mjcz._V1_.jpg',
    plyrPictureSrc: 'https://www.themiamiproject.org/wp-content/uploads/81338801_10-scaled.jpg',
    teamPrimary: '',
    teamSecondary: '',
    teamAlternate: '',
    teamLogo: '',
    teamLogoStyling: '',
    cardColor: 'ruby',
  },
  {
    name: 'Deion Sanders',
    firstName: "Deion",
    lastName: "Sanders",
    year: 1995,
    position: 'CB',
    team: 'DAL',
    fullTeamName: 'Dallas Cowboys',
    aproximateValue: 98,
    aproximateValuePeak: 22, 
    overallRating: 99,
    teamPlayStyle: 'Elusive Power-Back',
    plyrHeadshotSrc: '',
    plyrPictureSrc: '',
    teamPrimary: '',
    teamSecondary: '',
    teamAlternate: '',
    teamLogo: '',
    teamLogoStyling: '',
    cardColor: '',
  },
  {
    name: 'Barry Sanders',
    firstName: "Barry",
    lastName: "Sanders",
    year: 1999,
    position: 'RB',
    team: 'CLE',
    fullTeamName: 'Cleveland Browns',
    aproximateValue: 98,
    aproximateValuePeak: 22, 
    overallRating: 99,
    teamPlayStyle: 'Elusive Power-Back',
    plyrHeadshotSrc: '',
    plyrPictureSrc: '',
    teamPrimary: '',
    teamSecondary: '',
    teamAlternate: '',
    teamLogo: '',
    teamLogoStyling: '',
    cardColor: '',
  },
  {
    name: 'Randy Moss',
    firstName: "Randy",
    lastName: "Moss",
    year: 2007,
    position: 'WR',
    team: 'NWE',
    fullTeamName: 'New England Patriots',
    aproximateValue: 98,
    aproximateValuePeak: 19, 
    overallRating: 99,
    teamPlayStyle: 'Elusive Power-Back',
    plyrHeadshotSrc: 'https://static.hudl.com/users/temp/7862744_4077d748fbc94729ac07c7cd265c96c5.jpg',
    plyrPictureSrc: 'https://i.pinimg.com/originals/f2/72/45/f272457d7cd698e6c6b19a66bad0e359.jpg',
    teamPrimary: '',
    teamSecondary: '',
    teamAlternate: '',
    teamLogo: '',
    teamLogoStyling: '',
    cardColor: 'sapphire',
  },
  {
    name: 'Joe Montana',
    firstName: "Joe",
    lastName: "Montana",
    year: 1988,
    position: 'QB',
    team: 'CLE',
    fullTeamName: 'Cleveland Browns',
    aproximateValue: 98,
    aproximateValuePeak: 22, 
    overallRating: 99,
    teamPlayStyle: 'Elusive Power-Back',
    plyrHeadshotSrc: '',
    plyrPictureSrc: '',
    teamPrimary: '',
    teamSecondary: '',
    teamAlternate: '',
    teamLogo: '',
    teamLogoStyling: '',
    cardColor: '',
  },
  {
    name: 'Joe Greene',
    firstName: "Joe",
    lastName: "Greene",
    year: 1975,
    position: 'DT',
    team: 'CLE',
    fullTeamName: 'Cleveland Browns',
    aproximateValue: 98,
    aproximateValuePeak: 22, 
    overallRating: 99,
    teamPlayStyle: 'Elusive Power-Back',
    plyrHeadshotSrc: '',
    plyrPictureSrc: '',
    teamPrimary: '',
    teamSecondary: '',
    teamAlternate: '',
    teamLogo: '',
    teamLogoStyling: '',
    cardColor: '',
  },
  {
    name: 'Anthony Munoz',
    firstName: "Anthony",
    lastName: "Munoz",
    year: 1989,
    position: 'LT',
    team: 'CLE',
    fullTeamName: 'Cleveland Browns',
    aproximateValue: 98,
    aproximateValuePeak: 22, 
    overallRating: 99,
    teamPlayStyle: 'Elusive Power-Back',
    plyrHeadshotSrc: '',
    plyrPictureSrc: '',
    teamPrimary: '',
    teamSecondary: '',
    teamAlternate: '',
    teamLogo: '',
    teamLogoStyling: '',
    cardColor: '',
  },
  {
    name: 'Ronnie Lott',
    firstName: "Ronnie",
    lastName: "Lott",
    year: 1984,
    position: 'FS',
    team: 'CLE',
    fullTeamName: 'Cleveland Browns',
    aproximateValue: 98,
    aproximateValuePeak: 22, 
    overallRating: 99,
    teamPlayStyle: 'Elusive Power-Back',
    plyrHeadshotSrc: '',
    plyrPictureSrc: '',
    teamPrimary: '',
    teamSecondary: '',
    teamAlternate: '',
    teamLogo: '',
    teamLogoStyling: '',
    cardColor: '',
  },
  {
    name: 'Ray Lewis',
    firstName: "Ray",
    lastName: "Lewis",
    year: 2001,
    position: 'MLB',
    team: 'CLE',
    fullTeamName: 'Cleveland Browns',
    aproximateValue: 98,
    aproximateValuePeak: 22, 
    overallRating: 99,
    teamPlayStyle: 'Elusive Power-Back',
    plyrHeadshotSrc: '',
    plyrPictureSrc: '',
    teamPrimary: '',
    teamSecondary: '',
    teamAlternate: '',
    teamLogo: '',
    teamLogoStyling: '',
    cardColor: '',
  },
  {
    name: 'Don Hutson',
    firstName: "Don",
    lastName: "Hutson",
    year: 1949,
    position: 'WR',
    team: 'CLE',
    fullTeamName: 'Cleveland Browns',
    aproximateValue: 98,
    aproximateValuePeak: 22, 
    overallRating: 99,
    teamPlayStyle: 'Elusive Power-Back',
    plyrHeadshotSrc: '',
    plyrPictureSrc: '',
    teamPrimary: '',
    teamSecondary: '',
    teamAlternate: '',
    teamLogo: '',
    teamLogoStyling: '',
    cardColor: '',
  },
  {
    name: 'Marshall Faulk',
    firstName: "Marshall",
    lastName: "Faulk",
    year: 2000,
    position: 'RB',
    team: 'CLE',
    fullTeamName: 'Cleveland Browns',
    aproximateValue: 98,
    aproximateValuePeak: 22, 
    overallRating: 99,
    teamPlayStyle: 'Elusive Power-Back',
    plyrHeadshotSrc: '',
    plyrPictureSrc: '',
    teamPrimary: '',
    teamSecondary: '',
    teamAlternate: '',
    teamLogo: '',
    teamLogoStyling: '',
    cardColor: '',
  },
  {
    name: 'Emmitt Smith',
    firstName: "Emmitt",
    lastName: "Smith",
    year: 1993,
    position: 'RB',
    team: 'CLE',
    fullTeamName: 'Cleveland Browns',
    aproximateValue: 98,
    aproximateValuePeak: 22, 
    overallRating: 99,
    teamPlayStyle: 'Elusive Power-Back',
    plyrHeadshotSrc: '',
    plyrPictureSrc: '',
    teamPrimary: '',
    teamSecondary: '',
    teamAlternate: '',
    teamLogo: '',
    teamLogoStyling: '',
    cardColor: '',
  },
  {
    name: 'Ed Reed',
    firstName: "Ed",
    lastName: "Reed",
    year: 2009,
    position: 'SS',
    team: 'CLE',
    fullTeamName: 'Cleveland Browns',
    aproximateValue: 98,
    aproximateValuePeak: 22, 
    overallRating: 99,
    teamPlayStyle: 'Elusive Power-Back',
    plyrHeadshotSrc: '',
    plyrPictureSrc: '',
    teamPrimary: '',
    teamSecondary: '',
    teamAlternate: '',
    teamLogo: '',
    teamLogoStyling: '',
    cardColor: '',
  },
  {
    name: 'Rob Gronkowski',
    firstName: "Rob",
    lastName: "Gronkowski",
    year: 2011,
    position: 'TE',
    team: 'CLE',
    fullTeamName: 'Cleveland Browns',
    aproximateValue: 98,
    aproximateValuePeak: 22, 
    overallRating: 99,
    teamPlayStyle: 'Elusive Power-Back',
    plyrHeadshotSrc: '',
    plyrPictureSrc: '',
    teamPrimary: '',
    teamSecondary: '',
    teamAlternate: '',
    teamLogo: '',
    teamLogoStyling: '',
    cardColor: '',
  },
  {
    name: 'Tony Gonzales',
    firstName: "Tony",
    lastName: "Gonzales",
    year: 2005,
    position: 'TE',
    team: 'KAN',
    fullTeamName: 'Kansas City Chiefs',
    aproximateValue: 98,
    aproximateValuePeak: 22, 
    overallRating: 99,
    teamPlayStyle: 'Elusive Power-Back',
    plyrHeadshotSrc: '',
    plyrPictureSrc: '',
    teamPrimary: '',
    teamSecondary: '',
    teamAlternate: '',
    teamLogo: '',
    teamLogoStyling: '',
    cardColor: '',
  },
  {
    name: 'Marvin Harrison',
    firstName: "Marvin",
    lastName: "Harrison",
    year: 2003,
    position: 'WR',
    team: 'IND',
    fullTeamName: 'Indianapolis Colts',
    aproximateValue: 98,
    aproximateValuePeak: 22, 
    overallRating: 99,
    teamPlayStyle: 'Elusive Power-Back',
    plyrHeadshotSrc: '',
    plyrPictureSrc: '',
    teamPrimary: '',
    teamSecondary: '',
    teamAlternate: '',
    teamLogo: '',
    teamLogoStyling: '',
    cardColor: '',
  },
  {
    name: 'Jim Otto',
    firstName: "Jim",
    lastName: "Otto",
    year: 1979,
    position: 'C',
    team: 'LV',
    fullTeamName: 'Las Vegas Raiders',
    aproximateValue: 98,
    aproximateValuePeak: 22, 
    overallRating: 99,
    teamPlayStyle: 'Elusive Power-Back',
    plyrHeadshotSrc: '',
    plyrPictureSrc: '',
    teamPrimary: '',
    teamSecondary: '',
    teamAlternate: '',
    teamLogo: '',
    teamLogoStyling: '',
    cardColor: '',
  },
  {
    name: 'John Hannah',
    firstName: "John",
    lastName: "Hannah",
    year: 1983,
    position: 'LG',
    team: 'NWE',
    fullTeamName: 'New England Patriots',
    aproximateValue: 98,
    aproximateValuePeak: 22, 
    overallRating: 99,
    teamPlayStyle: 'Elusive Power-Back',
    plyrHeadshotSrc: '',
    plyrPictureSrc: '',
    teamPrimary: '',
    teamSecondary: '',
    teamAlternate: '',
    teamLogo: '',
    teamLogoStyling: '',
    cardColor: '',
  },
  {
    name: 'Bruce Matthews',
    firstName: "Bruce",
    lastName: "Matthews",
    year: 1989,
    position: 'RG',
    team: 'TEN',
    fullTeamName: 'Tennessee Titans',
    aproximateValue: 98,
    aproximateValuePeak: 22, 
    overallRating: 99,
    teamPlayStyle: 'Elusive Power-Back',
    plyrHeadshotSrc: '',
    plyrPictureSrc: '',
    teamPrimary: '',
    teamSecondary: '',
    teamAlternate: '',
    teamLogo: '',
    teamLogoStyling: '',
    cardColor: '',
  },
  {
    name: 'Forrest Gregg',
    firstName: "Forrest",
    lastName: "Gregg",
    year: 1964,
    position: 'RT',
    team: 'GNB',
    fullTeamName: 'Green Bay Packers',
    aproximateValue: 98,
    aproximateValuePeak: 22, 
    overallRating: 99,
    teamPlayStyle: 'Elusive Power-Back',
    plyrHeadshotSrc: '',
    plyrPictureSrc: '',
    teamPrimary: '',
    teamSecondary: '',
    teamAlternate: '',
    teamLogo: '',
    teamLogoStyling: '',
    cardColor: '',
  },
];